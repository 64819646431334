
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.portfolio-v2 {
  position: relative;
}

.portfolio-v2__title {
  margin-bottom: 32px;

  @include media(md) {
    width: 432px;
  }

  @include media(lg) {
    width: 694px;
    margin-bottom: 56px;
  }

  @include media(xl) {
    width: 776px;
  }
}

.portfolio-v2__list {
  @include ulres;

  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;

  @include media(md) {
    grid-template-columns: 1fr 1fr;
  }

  @include media(lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.portfolio-v2__item {
  border-radius: 12px;
  overflow: hidden;

  @include media(md) {
    height: 196px;
  }

  @include media(lg) {
    height: 172px;
    border-radius: 16px;
  }

  @include media(xl) {
    height: 218px;
  }

  &:nth-child(n + 4):not(:last-child) {
    @include media(sm-max) {
      display: none;
    }
  }

  &:nth-child(n + 6):not(:last-child) {
    @include media(md, md-max) {
      display: none;
    }
  }

  &:nth-child(n + 9):not(:last-child) {
    @include media(lg) {
      display: none;
    }
  }

  &--load-more {
    @include media(sm-max) {
      margin-top: 24px;
    }
  }
}

/* stylelint-disable */
.portfolio-v2__list--mini .portfolio-v2__item {
  &:nth-child(n + 6):not(:last-child) {
    @include media(lg) {
      display: none;
    }
  }
}
/* stylelint-enable */

.portfolio-v2__button {
  @include inpres;

  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;

  @include media(md) {
    height: 100%;
  }
}

.portfolio-v2__picture {
  position: relative;
  transition: all 250ms ease-out;

  @include media(sm-max) {
    padding-top: calc(100% / 16 * 9);
  }

  @include media(md) {
    height: 100%;
  }

  img {
    @include media(sm-max) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@include hover('.portfolio-v2__button') {
  .portfolio-v2__picture {
    transform: scale(1.05);
  }
}

.portfolio-v2__item:focus-within .portfolio-v2__picture {
  transform: scale(1.05);
}

.portfolio-v2__name {
  position: absolute;
  bottom: 12px;
  left: 12px;
}

.portfolio-v2__load-more:focus-visible {
  @include media(sm-max) {
    color: var(--accent-always-white-color);
    background-color: var(--accent-always-black-color);
  }

  @include media(md) {
    background-color: var(--bg-secondary-accent-color);
  }
}

/* stylelint-disable */
.portfolio-v2__load-more {
  @include inpres;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  text-align: left;
  background-color: var(--bg-secondary-accent-color);
  cursor: pointer;
  transition: all 70ms ease-in-out;

  @include media(md) {
    height: 100%;
    background-color: var(--bg-secondary-color);
  }

  @include hover {
    @include media(sm-max) {
      color: var(--accent-always-white-color);
      background-color: var(--accent-always-black-color);
    }

    @include media(md) {
      background-color: var(--bg-secondary-accent-color);
    }
  }
}

.is-safari .portfolio-v2__load-more:focus {
  @include media(sm-max) {
    color: var(--accent-always-white-color);
    background-color: var(--accent-always-black-color);
  }

  @include media(md) {
    background-color: var(--bg-secondary-accent-color);
  }
}
/* stylelint-enable */

.portfolio-v2__icon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 12px;

  @include media(md) {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.portfolio-v2__loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(var(--bg-main-color-rgb), .5);

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    margin-left: -25px;
  }
}

.portfolio-v2--loading .portfolio-v2__loader {
  display: block;
}
